import React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import parse, { domToReact } from 'html-react-parser'

import Layout from "../components/layout"
import Seo from "../components/seo"
import { useEffect } from 'react';

const OnlinebarTemplate = ({ data: { onlinebar, faceLeft, faceRight } }) => {
  const faceLeftImg = getImage(faceLeft.localFile)
  const faceRightImg = getImage(faceRight.localFile)

  const content = parse(onlinebar.content, {
    replace: domNode => {
      if (domNode.attribs && domNode.attribs.class) {
        if (domNode.attribs.class.indexOf('wp-block-create-block-tanoshige-editor-block-1') !== -1) {

          return (
            <div className="speaker">
              <span className="speaker-icon speaker-left"><GatsbyImage image={faceLeftImg} alt="対談者A" width={50} /></span>
              <p className="left-balloon">
                {domToReact(domNode.children)}
              </p>
            </div>
          )
        }

        if (domNode.attribs.class.indexOf('wp-block-create-block-tanoshige-editor-block-2') !== -1) {
          return (
            <div className="speaker">
              <span className="speaker-icon speaker-right"><GatsbyImage image={faceRightImg} alt="対談者B" width={50} /></span>
              <p className="right-balloon">
                {domToReact(domNode.children)}
              </p>
            </div>
          )
        }
      }
    }
  })

  useEffect(() => {
    let wpImages = document.querySelectorAll('.wp-block-image')
    let len = wpImages.length

    if (len < 2) {
      return
    }

    wpImages[len - 2].remove()
    wpImages[len - 1].remove()
  })

  return (
    <Layout isHomePage={true} headerClass="bg-black" isLogoOneWhite={true} isLogoTwoWhite={true}>
      <Seo title={onlinebar.title} description={onlinebar.excerpt} />
      <main id="onlinebar-detail">
        <section id="online-kv-box">
          <div id="online-ttl">
            <h2>
              <StaticImage src="../images/online-ttl.png" alt="広島オンライン酒場" />
              <StaticImage id="online-stamp" src="../images/online-stamp.png" alt="第２・第４金曜 20:00〜" />
            </h2>
            <h3><StaticImage src="../images/online-subttl.png" alt="Visible Radio Connected TV" /></h3>
          </div>
        </section>
        <article
          className="inner"
          itemScope
          itemType="http://schema.org/Article"
        >
          <section>
            <h1>{onlinebar.title}</h1>
            {!!onlinebar.content && (<section itemProp="articleBody" className="onlinebar-body">{content}</section>)}
          </section>
        </article>
        <Link className="link-btn" to="/onlinebar"><span>オンライン酒場一覧へ</span></Link>
      </main>
    </Layout>
  )
}

export default OnlinebarTemplate

export const pageQuery = graphql`
  query OnlinebarById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $faceLeftId: String!
    $faceRightId: String!
  ) {
    # selecting the current onlinebar by id
    onlinebar: wpOnlinebar(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "MMMM DD, YYYY")
      onlinebarFaceLeft
      onlinebarFaceRight
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO]
              )
            }
          }
        }
      }
    }
    faceLeft: wpMediaItem(guid: { eq: $faceLeftId }) {
      id
      localFile {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO]
          )
        }
      }
    }
    faceRight: wpMediaItem(guid: { eq: $faceRightId }) {
      id
      localFile {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO]
          )
        }
      }
    }
  }
`
